import music from './music/Music.mp3'
import logo from './ohsaad-logo.png';
import './App.css';
import { useRef,  useEffect } from 'react';

function App() {


  const Home = () => {


    const audio = useRef(new Audio(music))
  
    useEffect(() => {
      audio.current.playbackRate = 1.5;
      audio.current.volume = 0.8;
      audio.current.loop = true;

      
      const handleKeyPress = (e) => {
        if (e.key === "Escape") {
          console.log("Pausing Music");
          audio.current.pause();
        } else if (e.key === " ") { // Space key is represented by " "
          console.log("Pausing Music");
          audio.current.pause();
          e.preventDefault(); 
        }
      };
  
      document.addEventListener("keydown", handleKeyPress);

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };

    }, [])


    var elem = document.documentElement;
    /* View in fullscreen */
    function openFullscreen() {
      console.log("Starting Full Screen")
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
      console.log("Executing Music")
      audio.current.play()
    }
    
    

    return (
      <div>
        <button className='unstyled-button' onClick={() => openFullscreen()}>
            <div className="spin-infinite">
              <img src={logo} className="App-logo" alt="logo"/>
            </div>
        </button>
      </div>
    )
  }
  
  return (
    <div className="App">
      <header className="App-header">
        <Home/>
      </header>
    </div>
  );
}

export default App;
